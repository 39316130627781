<template>
<div>
  <!-- 时间 -->
  <div class="common-time" v-if="isShowTime&&item.time"><span>{{ item.time }}</span></div>
  <div class="left_item flex">
    <img :src="item.avatar||'https://livechat.kr65.com/resources/images/operator.png'" width="40" height="40" style="border-radius:50%;" :onerror="errorImage" />
    
    <!-- 纯文本类型 -->
    <div class="left_item-content" v-if="item.msg_type==1||item.msg_type==0" @click="clickMsg">
      <!-- <div class="flex-cs left_item-content-top">
        <span>{{ item.nickname }}</span>
        <span>{{ item.time }}</span>
      </div> -->
      <input type="text" id="copyTxt">
      <div class="left_item-content-tips" v-if="isShowCopy&&curClickIndex==curIndex"><span @click.stop="copy(item.content)" style="color:#fff;">复制</span></div>
      <pre ><p style="display: flex;align-items: center;flex-wrap:wrap;white-space: pre-wrap;word-wrap: break-word;text-align:left;" v-html="$options.filters.filterMsg(item.content,emojiObj)" ></p></pre>
    </div>
    <!-- 图片类型 -->
    <div class="left_item-content" v-if="item.msg_type==2">
      <imgViewer v-for="(item,index) in item.json.entity" :key="index" :attachments="item.url" :buttonName="item.url" :type="'img'" imgWrapper="editWork" />
    </div>
    <!-- 信令消息 -->
    <div class="left_item-content" v-if="item.msg_type==66">
      <!-- 邀请评价 -->
      <p class="pointer blueColor underline" @click="clickInvite(item.json.entity)" v-if="item.json.rule=='evaluation_invite'">{{item.json.entity.invite.message}}</p>
    </div>
    <!-- 扩展消息 -->
    <div class="left_item-content" v-if="item.msg_type==77">
      <!-- 人工类型 -->
      <selfType :list="item.json.entity" @on-click="clickSelf" v-if="item.json!=''&&item.json.rule=='menu'" />
      <!-- 名片类型 -->
      <cardType :list="item.json.entity" v-if="item.json!=''&&item.json.rule=='card'" />
      <!-- 产品类型 -->
      <productType :list="item.json.entity" @on-click="clickProduct" v-if="item.json!=''&&item.json.rule=='product'"/>
      <!-- 订单类型 -->
      <orderType :list="item.json.entity" @on-click="clickOrder" v-if="item.json!=''&&item.json.rule=='order'" />
    </div>

  </div>
</div>
  
</template>
<script>
export default {
  name: "leftItem",
  components:{
    selfType:()=>import("./selfType"),
    cardType:()=>import("./cardType"),
    orderType:()=>import("./orderType"),
    productType:()=>import("./productType"),
    imgViewer:()=>import("../ImgViewer/imgViewer"),
  },
  props: {
    item: Object,
    avatar:String,
    emojiObj:[Object,Array],
    isShowTime:Boolean,   //是否显示时间
    curClickIndex:Number,
    curIndex:Number
  },
  data(){
      return {
        errorImage: 'this.src="' + require('@/assets/images/error_img.jpg') + '"'  ,
        isShowCopy:false,
      }
  },
    // 筛选器
  filters: {
    // 使用过滤器 将表情包编码插入到img容器中
    filterMsg(e, data) {
      let str = e.replace(/\[ee_([0-9]*)\]/g, (matched) => {
        let src = data[matched];
        return `<img src="${src}" border="0" />`;
      });
      return str;
    },
  },
  methods:{
    // e:子组件的传参
    clickProduct(e){
      this.$emit("on-click",e,"product");//将方法抛出给父组件，在父组件中用@on-click来使用
    },
    clickSelf(e){
      this.$emit("on-click",e,"self");//将方法抛出给父组件，在父组件中用@on-click来使用
    },
    clickOrder(e){
      this.$emit("on-click",e,"order");//将方法抛出给父组件，在父组件中用@on-click来使用
    },
    clickInvite(e){

      this.$emit("on-click",e,"invite");//将方法抛出给父组件，在父组件中用@on-click来使用
    },
    clickMsg(){
      this.isShowCopy = true;
      this.$emit("update:curClickIndex",this.curIndex)
    },
    async copy(e){
      this.isShowCopy = false;
      let target = document.getElementById('copyTxt') //创建input节点
      target.value = e // 给input的value赋值
      target.select() // 选中input
      try {
        await document.execCommand('Copy') // 执行浏览器复制命令
         console.log('复制成功')
      } catch {
        console.log('复制失败')
      }
    },
  }
}
</script>
<style lang="scss" scoped>
@import "@/assets/style/basic.scss";
.left_item {
  margin-bottom: 30px;
  padding-right: 50px;
  box-sizing: border-box;
  &-content {
    min-width: 150px;
    word-break: break-all;
    background-color: red;
    margin-left: 10px;
    border-radius: 5px;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.06);
    background: #fff;
    // border: 1px solid #d4d4d4;
    color: #454545;
    padding: 10px 10px;
    box-sizing: border-box;
    position: relative;
    &::before{
      content:"";
      position: absolute;
      top: 16px;
      left:-3px;
      height: 10px;
      width: 10px;
      background-color: #fff;
      transform: rotate(135deg)
    }
    &-top {
      margin-bottom: 5px;
      span {
        display: inline-block;
        margin-left: 20px;
        &:nth-of-type(1) {
          margin-left: 0;
        }
      }
    }
    &-tips{
      position: absolute;
      top: -33px;
      left: 50%;
      transform: translateX(-50%);
      display: flex;
      background-color: #000;
      border-radius: 5px;
      &::after{
        content:"";
        position: absolute;
        bottom: -6px;
        left: 50%;
        transform: translateX(-50%);
        width: 0;
        height: 0;
        border-top:6px solid #000;
        border-left:6px solid transparent;
        border-right:6px solid transparent;
      }
      span{
        display: inline-block;
        padding: 5px 10px;
        box-sizing: border-box;
        font-size:12px;
        cursor: pointer;
      }
    }
    #copyTxt {
      position: absolute;
      top: 0;
      left: 0;
      opacity: 0;
      z-index: -20;
    }
    p {
      line-height: 25px;
    }
  }
}
</style>